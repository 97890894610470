@use './typography';

.chat-container {
  position: fixed;
  bottom: 50px;
  right: var(--space-8);
  display: flex;
  z-index: 100;

  &.open {
    height: 600px;
    bottom: var(--space-4);
  }

  .chat-icon {
    width: 77px;
    height: 77px;
    background-color: var(--c-primary-2);
    @include typography.graphik(15px, 18px, 500, -0.2px, white);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--c-primary-3);
  }

  .chat-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 350px;
    border-radius: 30px 30px 0 0;
    background-color: var(--c-gray);
    box-shadow: 0px -3px 8px 0px #9facbd40;
    padding: 0 var(--space-4) var(--space-4) var(--space-4);

    .chat-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-title {
        @include typography.mabry(23px, 28px, 500, -0.3px, var(--c-dark));
      }

      .exit-button {
        cursor: pointer;
        width: var(--space-8);
        height: var(--space-8);
        border-radius: 50%;
        background-color: var(--c-primary-2);
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
          width: 20px;
          height: 20px;
          filter: brightness(0) invert(1);
        }
      }
    }

    .messages {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      flex-grow: 2;
      margin-bottom: var(--space-3);
      padding-right: var(--space-1);

      .message-container {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        justify-content: flex-end;
        margin-top: var(--space-3);

        &.bot {
          flex-direction: row-reverse;
        }

        .message {
          padding: var(--space-2) var(--space-4);
          border-radius: var(--space-1);
          max-width: 80%;

          &.user {
            background-color: #f0eeeb;
            align-self: flex-end;
          }

          &.bot {
            background-color: #ffed80;
            align-self: flex-start;
          }
        }

        .message-icon {
          width: var(--space-6);
          height: var(--space-6);
          border-radius: 50%;
          background-position: center;

          &.user {
            background-color: #f0eeeb;
            background-image: url('https://cdn.aftenposten.no/vink/images/icons/user.svg');
            background-repeat: no-repeat;
            margin-left: var(--space-1);
          }

          &.bot {
            background-color: #ffed80;
            background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/comments/comments-dark.svg');
            background-repeat: no-repeat;
            background-size: 18px 18px;
            background-position: center;
            margin-right: var(--space-1);
          }
        }

        .typing-indicator .message.typing {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffed80;
          border-radius: var(--space-1);
          padding: var(--space-2) var(--space-4);
        }

        .typing-indicator .dot {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin: 0 2px;
          background-color: var(--c-dark);
          border-radius: 50%;
          animation: typing 1.5s infinite;
        }

        .typing-indicator .dot:nth-child(1) {
          animation-delay: 0s;
        }

        .typing-indicator .dot:nth-child(2) {
          animation-delay: 0.3s;
        }

        .typing-indicator .dot:nth-child(3) {
          animation-delay: 0.6s;
        }

        @keyframes typing {
          0%,
          80%,
          100% {
            opacity: 0;
          }
          40% {
            opacity: 1;
          }
        }
      }
    }

    .input-container {
      display: flex;
      flex-direction: row;
      border: solid 1px var(--c-primary-3);
      padding: var(--space-1);
      border-radius: 20px;
      background-color: white;

      .input-field {
        flex-grow: 2;
        border: none;
        padding: var(--space-2);
        border-radius: 20px;
        outline: none;
        font-size: 16px;
        color: var(--c-dark);
        &::placeholder {
          color: var(--c-primary-3);
        }
        &:focus {
          outline: none;
        }
      }

      .send-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--c-primary-2);
        padding: var(--space-2) var(--space-4);
        border-radius: 20px;
        @include typography.graphik(15px, 18px, 500, -0.2px, white);
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;

        img {
          height: 16px;
          width: 16px;
          margin-left: var(--space-2);
        }
      }
    }
  }

  &.mobile {
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    &.open {
      height: 99%;
      bottom: 0;
      width: 100%;
    }
    .chat-window {
      width: 100%;
    }
  }

  .recommendation-card {
    text-decoration: none;
    padding: var(--space-2);
    margin-top: var(--space-2);
    border: solid 1px black;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-content: center;

    &__title {
      @include typography.mabry(14px, 16px, 500, -0.3px, var(--c-dark));
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__area {
        @include typography.graphik(10px, 16px, 500, -0.2px, var(--c-dark));
        margin-right: var(--space-1);
      }

      &__divider {
        width: 1px;
        height: 80%;
        background-color: black;
        margin-right: var(--space-1);
      }

      .front-grade {
        @include typography.mabry(13px, 18px, 700, 0, var(--c-dark));
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 18px;
      }

      .bold-grade {
        margin-right: var(--space-1);
      }

      .marks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-column-gap: 3px;
        grid-row-gap: 3px;
      }

      .mark {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--c-dark);
        opacity: 0.3;

        &.bold {
          opacity: 1;
        }
      }
    }
  }
}
