$cdnUrl: 'https://cdn.aftenposten.no/ap/fonts/';

@mixin cdn-font-face($family, $path, $filename, $weight: null, $style: null) {
  @font-face {
    font-family: $family;
    src:
      local($family),
      local('$filename'),
      url($cdnUrl + $path + $filename + '.woff2') format('woff'),
      url($cdnUrl + $path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin publicoHeadline($name, $weight, $style: null) {
  @include cdn-font-face(
    'Publico Headline',
    'pbh/',
    'PublicoHeadline' + $name,
    $weight,
    $style
  );
}

@include publicoHeadline('-Extrabold', 800);
@include publicoHeadline('-Bold', 700);
@include publicoHeadline('-Medium', 500);
@include publicoHeadline('', 'normal');
@include publicoHeadline('-Light', 300);
@include publicoHeadline('-Italic', 'normal', 'italic');
@include publicoHeadline('-BoldItalic', 700, 'italic');

@mixin publicoText($name, $weight, $style: null) {
  @include cdn-font-face(
    'Publico',
    'pbt/',
    'PublicoText' + $name,
    $weight,
    $style
  );
}

@include publicoText('', 'normal');
@include publicoText('-Italic', 'normal', 'italic');

@mixin graphik($name, $weight) {
  @include cdn-font-face('Graphik', 'graphik/', 'Graphik' + $name, $weight);
}

@include graphik('-Semibold', 600);
@include graphik('-Medium', 500);
@include graphik('', 'normal');
@include graphik('-Light', 300);

@mixin graphikXCond($name, $weight) {
  @include cdn-font-face(
    'GraphikXCond',
    'graphik/',
    'GraphikXCond' + $name,
    $weight
  );
}

@mixin roboto($name, $weight) {
  @include cdn-font-face('Roboto', 'r/', 'Roboto' + $name, $weight);
  // ! collapse into one font name and proper use of font weights across 120+ rules
  @include cdn-font-face('Roboto' + $name, 'r/', 'Roboto' + $name, $weight);
}

@include roboto('-Bold', 600);
@include roboto('', 'normal');
@include roboto('-Medium', 300);
@include roboto('-Light', 300);

@include cdn-font-face('icon', 'icons/', 'subset');

@mixin produkt($name, $filenameSuffix, $weight) {
  @include cdn-font-face(
    $name,
    'produkt/',
    'Produkt' + $filenameSuffix,
    $weight
  );
}

@include produkt('Produkt', '-Semibold', 600);
@include produkt('Produkt', '-Black', normal);
@include produkt('Produkt', '-Bold', 600);
@include produkt('Produkt', '-Italic', 300);
@include produkt('Produkt', '-Medium', 300);
@include produkt('Produkt', '', normal);
@include produkt('ProduktXXCond', 'XXCond-Semibold', 600);
@include produkt('ProduktXXCond', 'ProduktXXCond', normal);

@mixin inter($name, $weight) {
  @include cdn-font-face('Inter', 'inter/', 'Inter' + $name, $weight);
}

@include inter('-Bold', 700);
