@use './typography';
@use './animations';

.navigation {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: var(--c-light);
  color: var(--c-dark);

  a {
    text-decoration: none;
    @include typography.graphik(16px, 16px, 500, 0, var(--c-dark));
  }
  .container {
    width: 100%;
    max-width: var(--max-width);
    min-height: 39px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: var(--space-4);
    margin: auto;
    padding: var(--space-6) 0;
  }

  .logo {
    img {
      filter: var(--filter-dark);
      width: 139px;
    }
  }

  .subscribe {
    text-decoration: none;
    padding: var(--space-3) var(--space-6);
    margin-right: var(--space-8);
    border-radius: 4px;
    border: 1px solid var(--c-light);
    @include typography.graphik(15px, 15px, 500, -0.3px, var(--c-light));
    background-color: var(--c-primary-2);
    width: 137px;
  }

  .link {
    @include typography.mabry(18px, 18px, 400, 0, var(--c-dark));
    margin-bottom: var(--space-5);
    white-space: nowrap;
  }

  .drop-down-button {
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .admin {
    @include typography.graphik(14px, 18px, 600, 0, var(--c-light));
    background: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .section-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    margin-left: 6%;
    width: 620px;

    .section-link {
      padding-right: 9px;
      .icon {
        position: relative;
      }
    }
  }

  //hover animation
  .section-link,
  .link {
    transform: perspective(1px) translateZ(0);
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 100%;
      border-radius: 15px;
      transition: width 0.2s ease-out;
      z-index: -1;
    }
    &.byliv-og-guider::before {
      background-color: var(--c-pink-bright);
    }
    &.film-og-serier::before {
      background-color: var(--c-blue-bright);
    }
    &.mat-og-drikke::before {
      background-color: var(--c-yellow-bright);
    }
    &.top-lists::before {
      background-color: var(--c-green-bright);
    }

    &:hover::before {
      width: 100%;
    }

    &.active {
      font-weight: 600;
      background-color: var(--c-primary-3);
      border-radius: 15px;

      &.byliv-og-guider {
        background-color: var(--c-pink-medio);

        .icon {
          background-color: var(--c-pink-medio);
        }
      }
      &.film-og-serier {
        background-color: var(--c-blue-medio);

        .icon {
          background-color: var(--c-blue-medio);
        }
      }
      &.mat-og-drikke {
        background-color: var(--c-yellow-medio);

        .icon {
          background-color: var(--c-yellow-medio);
        }
      }
      &.top-lists {
        background-color: var(--c-green-medio);

        .icon {
          background-color: var(--c-green-medio);
        }
      }

      &::before {
        display: none;
      }
    }
  }

  .admin-container {
    display: flex;
    align-items: center;

    .search {
      width: 24px;
      margin-right: var(--space-5);
      height: 35px;
    }

    .bookmark {
      margin-right: var(--space-5);
    }

    .login-wrapper {
      display: flex;
    }
  }

  &.transparent {
    background: linear-gradient(
      180deg,
      rgba(0, 7, 36, 0.1) 40.48%,
      rgba(0, 34, 119, 0) 99.18%
    );
    position: absolute;
    filter: drop-shadow(0px 1px 2px rgba(38, 0, 0, 0.31));

    .section-links .link:not(.active) {
      color: var(--c-light);

      &:hover {
        color: var(--c-dark);
      }
    }

    .subscribe {
      background-color: var(--c-light);
      color: var(--c-dark);
    }

    .logo,
    .drop-down-button,
    .search,
    .bookmark {
      background: transparent;
      img {
        filter: var(--filter-light);
      }
    }
  }
  &.sticky {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .link,
  .section-link {
    &.front,
    &.byliv-og-guider,
    &.film-og-serier,
    &.mat-og-drikke,
    &.top-lists,
    &.login,
    &.logout,
    &.aftenposten {
      display: flex;
      align-items: center;
      padding-right: var(--space-2);

      .icon {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 15px;
        margin-right: var(--space-1);

        img {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translate(-50%, -50%);
        }
      }
    }
    &.godt {
      margin-top: var(--space-5);

      .icon {
        img {
          padding-top: 3px;
          width: 100%;
        }
      }
    }
    &.samtykke {
      margin-top: var(--space-5);
    }
    &.byliv-og-guider .icon {
      background-color: var(--c-pink-bright);
      &::after {
        filter: var(--filter-pink);
      }
    }
    &.film-og-serier .icon {
      background-color: var(--c-blue-bright);
      &::after {
        filter: var(--filter-blue-medio);
      }
    }
    &.mat-og-drikke .icon {
      background-color: var(--c-yellow-bright);
      &::after {
        filter: var(--filter-yellow);
      }
    }
    &.retaurantguiden .icon {
      background-color: var(--c-green-bright);
      &::after {
        filter: var(--filter-green);
      }
    }
    &.login,
    &.logout,
    &.aftenposten {
      &::after {
        filter: brightness(0) invert(1);
      }
    }
  }

  .logout {
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .user,
  .logout,
  .login {
    margin-bottom: var(--space-5);
    @include typography.graphik(16px, 16px, 500, -0.2px, var(--c-dark));
  }
  .aftenposten {
    @include typography.graphik(16px, 16px, 500, -0.2px, var(--c-dark));
    margin-bottom: 0;
  }
}

.menu-wrapper {
  position: relative;

  .menu {
    position: absolute;
    top: 42px;
    right: 0;
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--c-gray);
    border-radius: var(--space-1);
    padding: var(--space-8) var(--space-6);
    box-shadow: 0px 4px 4px rgba(0, 7, 36, 0.16);
  }
}

.review-article,
.guide-article {
  .navigation {
    border-bottom: none;
  }
}
.tablet-front,
.tablet-article-container {
  .subscribe-bar-container {
    padding: 0 32px;
    width: auto;
  }
}
.tablet-front,
.tablet-article-container {
  .navigation {
    .container {
      padding: var(--space-6) calc(16px - clamp(0px, -50px + 6.5625vw, 16px));
      .section-links {
        max-width: 620px;
        width: 100%;
        margin-left: auto;
        justify-content: center;
        gap: clamp(4px, -62px + 8.5914vw, 26px);
        .section-title {
          font-size: clamp(14px, calc((8px + 0.8vw) - 0.144px), 18px);
        }
      }
      .logo {
        img {
          width: clamp(90px, -210px + 39vw, 139px);
        }
      }
    }
  }
}
// mobile front
.phone-front,
.mobile-article {
  .navigation {
    padding: var(--space-5) var(--space-3) var(--space-5);
    width: 100%;

    .subscribe {
      width: unset;
      margin-right: 0;
      padding: var(--space-3) var(--space-2);
      font-size: 14px;
      line-height: 14px;
    }

    .container {
      padding: 0;
      margin: 0;
      width: 100%;
      justify-content: space-between;

      .logo {
        margin-top: 25px;
        margin: 0;

        img {
          width: 130px;
        }
      }
    }

    .section-links {
      display: block;
      width: auto;
      margin-left: 0;
    }

    .menu button {
      @include typography.graphik(16px, 16px, 500, 0, var(--c-dark));
    }
    .menu .link {
      .section-title {
        @include typography.graphik(16px, 16px, 500, 0, var(--c-dark));
      }
    }
  }
}
