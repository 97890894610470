@use './typography';
@use './colors';
@use './animations';
@use './components/chat.scss';
@use './components/fake-door-chat.scss';

.bundle {
  margin: 0 auto;
  max-width: var(--max-width);
  padding-bottom: var(--bottom-space);
  width: 100%;
}

.bundle-header {
  border-top: 2px solid var(--c-dark);
  padding-top: var(--space-8);
  padding-bottom: var(--space-10);
}

.bundle-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.bundle-title {
  @include typography.mabry(48px, 52.8px, 700, -0.5px);
  text-transform: uppercase;
}

.bundle-background {
  margin-top: 12px;
  @include typography.graphik(18px, 24px, 400);
}

.bundle-outro {
  display: flex;
  align-items: center;
  margin-top: var(--space-6);

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--c-dark);
  }
  .bundle-outro-link {
    @include typography.graphik(18px, 26px, 400, 0, var(--c-dark));
    text-decoration: none;
    flex-shrink: 0;
    margin-left: var(--space-2);
    margin-right: -2em;
    padding-right: 2em;
  }
  &::after {
    content: '';
    margin-left: var(--space-2);
    background-image: url(https://cdn.aftenposten.no/vink/images/icons/arrows/dark_blue_circle_arrow.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }

  &.no-link::after {
    content: none;
  }
}

.bundle-teaser-container,
.bundle-teasers {
  display: flex;
}

.bundle.grid {
  .bundle-teasers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 10px;
  }
  .teaser:nth-child(3) {
    margin-right: 0;
  }
}

.greeting {
  @include typography.mabry(40px, 45px, 500, -0.2px);
  @include animations.fadeIn(1s);
  margin: 0 auto;
  padding: var(--space-12) var(--bottom-space) var(--space-12) 4.6em;
  width: var(--max-width);

  &-top {
    color: var(--c-primary-2);
  }
  &-bottom {
    max-width: 673px;
  }
}

.vev-subscription {
  height: 100%;
  background-color: var(--c-dark);
  iframe::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.hermes-sales-page {
  padding: 105px 0 80px;
  background-color: #011133;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 150%;
  line-height: 1.5;
}

.breadcrumb {
  margin: var(--space-6) auto var(--space-8);
  width: var(--max-width);

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include typography.graphik(12px, 16px, 400, 0, var(--c-dark-gray));

    img {
      width: 9px;
      margin: 0 var(--space-1);
      filter: var(--filter-dark-gray);
    }

    .section {
      font-weight: 600;
      color: var(--c-dark);
    }
  }
}

.reels-container {
  margin-bottom: var(--space-10);
}

.phone-front {
  .bundle {
    display: flex;
    flex-direction: column;
  }
  .bundle-header {
    padding-top: var(--space-4);
    padding-bottom: var(--space-6);
    margin-left: var(--space-5);
    margin-right: var(--space-5);
  }
  .bundle-title {
    @include typography.mabry(32px, 44px, 700, -0.32px);
  }
  .bundle-outro {
    margin: var(--space-4) var(--space-5) 0 var(--space-5);
    &.no-link {
      margin-top: 0;
    }
  }
  .bundle-outro-link {
    @include typography.graphik(17px, 25px, 400, 0, var(--c-dark-gray));
  }
  .bundle-teasers,
  .bundle-teaser-container {
    flex-direction: column;
  }

  .breadcrumb {
    width: 100%;
    padding-right: var(--space-4);
  }

  .reels-container {
    margin-bottom: var(--space-5);
  }
}
.tablet-front {
  .bundle.grid .bundle-teasers {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    max-width: var(--max-width);
    width: 100%;
  }
  .bundle-teasers {
    gap: var(--space-4);
    flex-wrap: wrap;
    max-width: var(--max-width);
    justify-content: center;
    width: 100%;
    .teaser {
      flex: 1;
      .image {
        width: 100%;
      }
    }
    .teaser:not(:last-of-type):not(.teaser-search-result) {
      margin-right: 0;
    }
  }
}
