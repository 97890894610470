@use './typography';

.vink-o-mat {
  display: flex;
  justify-content: center;
  margin: var(--space-2) 0;

  .chat-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 390px;
    border-radius: var(--space-2);
    background-color: var(--c-yellow-bright);
    padding: var(--space-4);

    .chat-header {
      display: flex;
      align-items: center;
      gap: var(--space-2);
      padding-bottom: var(--space-4);
      border-bottom: 2px solid var(--c-dark);

      .header-title {
        @include typography.mabry(28px, 1.2, 500, -0.2px, var(--c-dark));
        margin: 0;
      }

      .cutlery__icon {
        width: 24px;
        height: 24px;
      }
    }

    .chat-intro {
      margin: var(--space-4) 0;
      @include typography.graphik(18px, 26px, 400, 0, var(--c-dark));
    }

    .suggestions-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: var(--space-6) 0;
    }

    .suggestions-wrapper__suggestion {
      all: unset; // remove default button styles

      @include typography.graphik(18px, 26px, 400, 0, var(--c-primary-2));

      width: fit-content;
      margin: 0;
      display: flex;
      border: 1px solid var(--c-primary-2);
      padding: var(--space-2) var(--space-4);
      height: 26px;
      border-radius: 52px;

      &:active {
        color: var(--c-light);
        background-color: var(--c-primary-2);
      }

      &:hover {
        cursor: pointer;
      }
    }

    .typing-indicator .message.typing {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--space-1);
      padding: var(--space-2) var(--space-4);
    }

    .typing-indicator .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 0 2px;
      background-color: var(--c-primary-2);
      border-radius: 50%;
      animation: typing 1.5s infinite;
    }

    .typing-indicator .dot:nth-child(1) {
      animation-delay: 0s;
    }

    .typing-indicator .dot:nth-child(2) {
      animation-delay: 0.3s;
    }

    .typing-indicator .dot:nth-child(3) {
      animation-delay: 0.6s;
    }

    @keyframes typing {
      0%,
      80%,
      100% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
    }

    .messages {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      flex-grow: 2;
      padding-right: var(--space-1);

      .message-container {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        justify-content: flex-end;

        .message {
          border-radius: var(--space-1);
          max-width: 100%;
          margin: var(--space-6) 0 0 0;

          &__header {
            @include typography.graphik(19px, 25px, 500, -0.2px, var(--c-dark));
            padding: 0;
            margin: 0;
          }

          &__description {
            @include typography.graphik(18px, 25px, 400, -0.2px, var(--c-dark));
            padding: 0;
            margin: var(--space-2) 0 var(--space-6) 0;
          }

          &__detail {
            @include typography.graphik(18px, 25px, 400, -0.2px, var(--c-dark));
            margin: var(--space-4) 0 var(--space-4) 0;
          }

          &.bot {
            align-self: flex-start;
          }
        }
      }
    }

    .restart {
      padding-top: 24px;
      border-top: 1px solid var(--c-dark);
      &__button {
        @include typography.graphik(15px, 18px, 500, -0.2px, var(--c-light));

        text-align: center;
        width: fit-content;
        background-color: var(--c-primary-2);
        height: 48px;
        border-radius: 24px;
        padding: var(--space-2) var(--space-5);
        border: unset;

        &:hover {
          cursor: pointer;
        }
      }
      .restart__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--space-4);

        .close-chat-icon {
          width: 16px;
          height: 16px;
          filter: invert(1);
        }
      }
    }

    .finding-results {
      padding: var(--space-4) 0;

      &__info {
        @include typography.graphik(19px, 25px, 500, -0.1px, var(--c-dark));
        margin: 0;
        padding-bottom: var(--space-4);
      }

      &__illustration {
        display: flex;
        justify-content: center;
        margin: var(--space-4) auto;
        width: 120px;
        height: 120px;
      }
    }

    .user-searched {
      display: flex;
      border-bottom: 1px solid var(--c-dark);
      padding: var(--space-4) 0;

      &__text {
        margin: 0;
        gap: var(--space-4);
        @include typography.graphik(15px, 18px, 500, -0.2px, var(--c-dark));
      }

      &__value {
        @include typography.graphik(
          15px,
          17px,
          400,
          -0.3px,
          var(--c-primary-2)
        );
      }
    }

    .input-wrapper {
      display: flex;
      align-items: end;
      gap: 8px;
    }

    .input-container {
      display: flex;
      align-items: center;
      width: 100%;
      border: solid 1px var(--c-primary-2);
      padding: 10px 16px;
      border-radius: 10px;
      background-color: #fff;
      opacity: 0.8;

      .input-field {
        height: 28px;
        overflow-y: hidden;
        resize: none;

        width: 100%;
        border: none;
        outline: none;
        padding: 0;
        font-size: 16px;
        color: var(--c-dark);
        word-wrap: break-word;
        @include typography.graphik(17px, 25px, 400, 0, var(--c-primary-2));

        &::placeholder {
          color: var(--c-primary-2);
        }
        &:focus {
          outline: none;
        }
      }
    }

    .send-button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      min-height: 48px;
      background-color: var(--c-primary-2);
      border: none;
      cursor: pointer;
      padding: var(--space-2);
      border-radius: 24px;

      img {
        height: 16px;
        width: 16px;
        filter: invert(1);
      }
    }

    .rate-question {
      margin-top: 0;
      @include typography.graphik(15px, 18px, 500, -0.2px, var(--c-dark));
    }

    .rate-button-container {
      display: flex;
      align-items: center;
      gap: 8px;

      .heart-icon-img {
        width: 16px;
        height: 16px;
        margin-bottom: var(--space-6);
      }

      .feedback-paragraph {
        padding: 0;
        margin: 0 0 var(--space-6) 0;
        @include typography.graphik(12px, 16px, 400, 0, var(--c-dark));
      }

      .rate-button {
        all: unset;
        display: flex;
        height: 24px;
        border-radius: 48px;
        align-items: center;
        padding: var(--space-2) var(--space-4);
        gap: 4px;
        border: 1px solid var(--c-primary-2);
        margin-bottom: var(--space-6);
      }
    }
  }

  .recommendation-card {
    text-decoration: none;
    padding: var(--space-4);
    margin-top: var(--space-6);
    border: solid 1px var(--c-primary-2);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-content: center;

    &__title {
      @include typography.mabry(19px, 25px, 500, -0.05px, var(--c-primary-2));
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__divider {
        margin: 0 var(--space-2);
        width: 1px;
        height: 80%;
        background-color: black;
        margin-right: var(--space-1);
      }

      .front-grade {
        @include typography.mabry(16px, 18px, 700, 0, var(--c-primary-2));
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 18px;
      }

      .bold-grade {
        margin-right: var(--space-1);
      }

      .marks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-column-gap: 3px;
        grid-row-gap: 3px;
      }

      .mark {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--c-primary-2);
        opacity: 0.3;

        &.bold {
          opacity: 1;
        }
      }
    }
  }
}
